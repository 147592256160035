import React, { useState } from 'react';

import { IconSpinner, LayoutComposer } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';

import { validProps } from './Field';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import css from './PageBuilder.module.css';
import { SectionNavBar } from './SectionBuilder/index.js';
import FooterComponent from '../FooterContainer/FooterContainer.js';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    currentPage,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }
  const [email, setEmail] = useState('');
  const [formState, setFormState] = useState({
    inProgress: false,
    isSubmitted: false,
    error: null,
  });

  const handleSubmit = async e => {
    e.preventDefault();
    // Here you would typically send the email to your API
    setFormState({ inProgress: true, isSubmitted: false, error: null });
    const response = await axios.post(`${apiBaseUrl()}/api/newsletter-signup`, { email });
    if (response.status === 200) {
      setFormState({ inProgress: false, isSubmitted: true, error: null });
      setEmail('');
    } else {
      setFormState({ inProgress: false, isSubmitted: false, error: 'Something went wrong' });
    }
  };
  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);
  const isLandingPage = currentPage === 'landingPage';

  const layoutAreas = `
    topbar
    main
    footer
  `;
  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer currentPage={currentPage} />
                <SectionNavBar />
              </Topbar>
              <Main as="main" className={css.main}>
                {sections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <SectionBuilder sections={sections} options={options} />
                )}
              </Main>
              <Footer>
                {isLandingPage && (
                  <section className={css.section}>
                    <div className={css.container}>
                      <div className={css.card}>
                        <div className={css.content}>
                          <h2 className={css.title}>Stay Connected to PCIFIC</h2>
                          <p className={css.description}>
                            Join our newsletter for the latest updates on the UK's premier tech
                            marketplace. Get exclusive deals, seller tips, and tech trends!
                          </p>
                          <div className={css.highlight}>
                            <svg
                              className={css.icon}
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="13.708000183105469 13.966249465942383 36.5829963684082 36.06775665283203"
                              enableBackground="new 0 0 64 64"
                            >
                              <g>
                                <path
                                  fill="#FFFFFF"
                                  stroke="#000000"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  d="M35.059,15.472l12.634,9.309c1.319,0.972,2.098,2.513,2.098,4.152v11.705c0,4.913-3.983,8.896-8.896,8.896H23.104   c-4.913,0-8.896-3.983-8.896-8.896V28.933c0-1.639,0.779-3.18,2.098-4.152l12.634-9.309C30.76,14.131,33.24,14.131,35.059,15.472z"
                                />
                              </g>
                              <g>
                                <g></g>

                                <path
                                  fill="#FFFFFF"
                                  stroke="#536DFE"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  d="M43.237,32.811l-9.419,6.728c-1.087,0.777-2.548,0.777-3.635,0l-9.419-6.728V29.23c0-1.659,1.345-3.004,3.004-3.004h16.466   c1.659,0,3.004,1.345,3.004,3.004V32.811z"
                                />
                                <g>
                                  <line
                                    fill="none"
                                    stroke="#536DFE"
                                    strokeWidth="1"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    x1="26.382"
                                    y1="30.806"
                                    x2="32"
                                    y2="30.806"
                                  />
                                </g>
                              </g>
                              <g>
                                <path
                                  fill="none"
                                  stroke="#536DFE"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  d="M18.89,31.474l11.315,8.082c1.074,0.767,2.516,0.767,3.589,0l11.315-8.082"
                                />
                              </g>
                            </svg>
                            <span>Weekly tech insights delivered</span>
                          </div>
                        </div>
                        <div className={css.formContainer}>
                          <span>{formState.error}</span>

                          {!formState.isSubmitted ? (
                            <form onSubmit={handleSubmit} className={css.form}>
                              <div className={css.inputWrapper}>
                                <input
                                  type="email"
                                  id="email"
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                                  required
                                  className={css.input}
                                  disabled={formState.inProgress}
                                  placeholder=" "
                                />
                                <label htmlFor="email" className={css.label}>
                                  Enter your email
                                </label>
                              </div>
                              <button type="submit" className={css.button}>
                                {formState.inProgress ? 'Sending...' : 'Subscribe Now'}
                              </button>
                            </form>
                          ) : (
                            <div className={css.successMessage} role="alert">
                              <p className={css.successTitle}>Thank you for subscribing!</p>
                              <p className={css.successDescription}>
                                Check your inbox / Junk Folder for a confirmation email.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                )}
                <FooterComponent />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

export default PageBuilder;
